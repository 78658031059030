import React from 'react'
import LayoutStatic from '../../components/LayoutStatic'
import { useIntl } from 'gatsby-plugin-intl'

function Terms(props) {
    const intl = useIntl()
    return (
        <LayoutStatic {...props} title={intl.formatMessage({ id: 'terms.title' })}>
        { intl.locale === 'ja' ? 
        <>
<h2>利用規約</h2>
<ol>
<li>本アプリ内に表示されるiTunes ストアやApple Musicのコンテンツの著作権は、すべて元のサービスやアーティスト等に帰属するものです。</li>

<li>本アプリが表示する楽曲の解析データは、利用者に参考情報を提供することを目的として本アプリが独自に解析しているものであり、その正確性を保証するものではありません。その数値などはアプリのバージョンによっても変化する可能性があります。</li>

<li>本アプリのご使用時は、再生音量には十分にご注意ください。アプリの動作時にノイズ等が発生する可能性もあります。本アプリを使用した結果、利用者の聴力に何らかの悪影響が生じた場合でも、当方は一切の責任を負わないものとします。</li>

<li>その他、利用者はApp Store標準のサービス規約に従う必要があります。</li>

<li>この利用規約は日本語（日本）で書かれたものです。この利用規約の翻訳版と日本語版の内容が相反する場合、日本語版の内容が優先されます。</li>
</ol>
</>
 :
<>
<h2>Terms of Use</h2>
<ol>
<li>The copyrights for the content from the iTunes Store and Apple Music that are displayed in this app belong to the original services and artists.</li>

<li>The song analysis data displayed in this app is analyzed uniquely by this app with the purpose of providing information to users for reference. Therefore, we do not guarantee its accuracy, and the values may differ depending on the version of the app.</li>

<li>When using this app, please be sufficiently careful about the playback volume. There is also a possibility that background noise occurs while using the app.</li>

<li>We take no responsibility for any adverse impact caused to the user's hearing ability as a result of using this app.</li>

<li>This terms are provided to users for convenience purposes only. Only the <a href ="/ja/terms">Japanese version</a> of this terms are officially applicable to users and to matters concerning this app and this website.</li>
</ol>
</>
    }
        </LayoutStatic>
    )
}

export default Terms
